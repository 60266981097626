<template>
  <div>
    <button-spinner v-if="!are_reports_empty && show_zip_button"
                    :loading="zipLoading"
                    class="ma-2 right"
                    variant="info"
                    @click="download_as_zip">
      <template v-slot:defaultText>
        <span>REPORTS TO ZIP</span>
      </template>
    </button-spinner>

    <!-- refresh is mixins method use to update all components -->
    <b-table :current-page="current_page"
             :fields="fields"
             :items="experiment_reports"
             :per-page="per_page"
             head-variant="light"
             hover
             responsive
             striped>
      <template v-if="show_filter"
                v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="experiment_reports"
                         :fields="fields"
                         :not_include_key="['actions', 'ordinalNumber']">
        </proposal-filter>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-comment v-if="!elevated"
                                  title="Go to experiment report"
                                  @click="go_to_experiment_report(row.item)"/>
      </template>
    </b-table>
    <b-table-footer v-model="loading"
                    :currentPage="current_page"
                    :data_length="experiment_reports.length"
                    :header-size="footerSize"
                    :pagination="footerPagination"
                    :perPage="per_page"
                    @change_page="change_page">
    </b-table-footer>
  </div>
</template>

<script>
  import BaseIconButtonComment from '@/components/baseComponents/baseIcons/BaseIconButtonComment';
  import ButtonSpinner from '@/components/buttonSpinner';
  import bTableFooter from '@/components/b_table_footer';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';

  export default {
    name: 'experiment_list',
    components: {
      BaseColumnOrdinalNumber,
      BaseIconButtonComment,
      ButtonSpinner,
      proposalFilter,
      bTableFooter,
    },
    props: {
      load_data_url: {
        type: String,
        default: '',
      },
      prop_fields: {
        type: Array,
        default: () => [],
      },
      show_zip_button: {
        type: Boolean,
        default: false,
      },
      show_filter: {
        type: Boolean,
        default: true,
      },
      elevated: {
        type: Boolean,
        default: false,
      },
      footerPagination: {
        type: Boolean,
        default: true,
      },
      footerSize: {
        type: Number,
        default: 1,
        validator: (val) => [1, 2, 3, 4, 5, 6].includes(val),
      },
    },
    computed: {
      are_reports_empty() {
        return this.experiment_reports.length === 0;
      },
    },
    data() {
      return {
        id: 1,
        per_page: 20,
        loading: false,
        zipLoading: false,
        current_page: 1,
        experiment_reports: [],
        fields: this.prop_fields,
      };
    },
    methods: {
      load_data() {
        this.loading = true;
        this.axios.get(this.load_data_url)
          .then((response) => {
            this.experiment_reports = this.process_documents(response.data);
            this.$emit('setup_proposal', this.experiment_reports);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$notify({ type: 'warn', title: 'Could not load data' });
          });
      },
      go_to_experiment_report(item) {
        this.$router.push(`/documents/${item._id.$oid}/experiment_reports`);
      },
      change_page(val) {
        this.current_page = val;
      },
      calculate_row_index(index) {
        return index + (this.per_page * (this.current_page - 1));
      },
      process_documents(documents) {
        return documents.map(
          (document) => ({
            ...document,
            created_at: this.created_at(document),
          }),
        );
      },
      download_as_zip() {
        this.zipLoading = true;
        this.axios({
          url: `${this.load_data_url}/pdf`,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'experiment_reports.zip');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            this.zipLoading = false;
          });
      },

    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>

</style>
